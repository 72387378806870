
import DashboardCard, { AttendanceCard, StandardCard } from "../../components/Card";
import { Grid, Tab, TabList, TabGroup, TabPanel, TabPanels } from "@tremor/react";
import ChartCard from "../../components/ChartCart";
import { useState } from "react";
import "./Insights.css";
import PieChartCustom from "../../components/Chart/PieChart";
import { apiPost } from "../../data/apidata";
import { processCallsList } from "../../components/charts/JourneyFunctions";
import { useEffect } from "react";
import BarChartCustom from "../../components/Chart/BarChart";
import { getColour, getDaysArray, groupBy } from "../../functions/objects_arrays";
import { Card } from "@mui/material";
import VerticalHistogram from "../../components/Chart/VerticalHistoram";
import AQDateRangePicker from "../../components/Chart/DateRangePicker/DateRangePicker";
import { useUserSettings } from "../../components/UserSettingsProvider/UserSettingsProvider";
import { secondsToHHMMSS } from "../../functions/dataHandler";
import AverageResponseChart from "../../components/AverageResponseChart/AverageResponseChart";
import TotalCallsChart from "../../components/TotalCalls/TotalCalls";
import GridLayout from "react-grid-layout";
import { Bar } from "react-chartjs-2";
import { columns_template_livecalls } from "../../components/AQDataTable/columns_templates";
import { columns_template_all_livecalls } from "../../components/AQDataTable/columns_templates";
import Insight_BarChartCustom from "./Insight_BarCharCustom";
//import { object } from "prop-types";

export default function Insights() {
  const sevenDaysAgo = new Date();
  sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
  sevenDaysAgo.setHours(0, 0, 0, 0); // Set to the start of the day

  const today = new Date();
  today.setHours(23, 59, 59, 999);

  const [value, setValue] = useState({
    from: sevenDaysAgo.toISOString(),
    to: today.toISOString(),
  });

  const { userSettings, updateSettings } = useUserSettings();
  const [data, setData] = useState(null);
  const [rawData, setRawData] = useState(null);
  const [error, setError] = useState("");
  const [searchVal, setSearchVal] = useState("");
  const [firstLoad, setfirstLoad] = useState(true);

  function getData() {

    const apiParams = {
      "siteID": `${userSettings.site.id}`,
      "start": `${value.from}`,
      "end": `${value.to}`
    };

    setfirstLoad(false);
    if ((apiParams == undefined) || (apiParams == null) || (apiParams == "")) return;
    if (apiParams == undefined) return;
    setData([]);
    apiPost(
      "/api/messages/reports/list",
      apiParams,
      (d) => {
        let r = d["message"]["data"][0];
        setRawData(r);
        r = processCallsList(r);
        setData(r);
      },
      (e) => {
        console.log(e)
        setData([]);
      }
    );
  }

  function updateSearchVal(e) {
    setSearchVal(e.target.value);
  }

  function updateDates(start, end) {
    setValue({
      from: start,
      to: end
    });
  }

  useEffect(() => getData(), [firstLoad === true]);
  useEffect(() => getData(), [value.from, value.to])

  return (

    <main className="px-12 py-12 dashboard_layout">

      <div>
        <AQDateRangePicker start={value.from} end={value.to} onChange={updateDates} />
      </div>

      <br />

      <div className="cards_list">
        <TotalCalls data={data} />
        <BusiestTimeOfDay data={data} />
        <AverageResponseTime data={data} />
        <BusiestDay data={data} />
        {/*<BusiestDay data={data} />*/}
      </div>

      <br />

      <div className="two_column_split">
        <ShowCard className="card_width_half" title="All Calls"><AllCallsPie data={rawData} /></ShowCard>
        <ShowCard className="card_width_half" title="Calls by Origin"><OriCallsPie data={data} /></ShowCard>
      </div>

      <br />

      <div className="two_column_split">
        <ShowCard className="card_width_half" title="Proactive v Reactive"><ProReactPie data={data} /></ShowCard>
        <ShowCard className="card_width_half" title="Emergency V Non Emergency"><EmergencyNon data={data} /></ShowCard>
      </div>
      
      <br />
      <div className="two_column_split">
        <ShowCard className="card_width_half" title="Calls by Day"><CallsByDay data={data} start={value.from} end={value.to} /></ShowCard>  
        <ShowCardRed className="card_width_half" title="">
          <AverageResponseChart data={rawData} />
        </ShowCardRed>
      </div>

      <br />

      <div className="two_column_split">
        <ShowCard className="card_width_full" title="Calls by Room">
          <CallsByRoom data={data} start={value.from} end={value.to} />
        </ShowCard>
        
      </div>

      <div className="two_column_split">
      <ShowCard className="card_width_half" title="Calls by Month"><CallsByMonth data={data} /></ShowCard>
      <ShowCard className="card_width_half" title="Calls by Zone"><CallsByZone data={data} /></ShowCard>
      </div>

    </main>

  );

}

function flattenJourneyArray(arr) {
  if (arr === null) return [];
  const rtn = [];

  Object.values(arr).forEach(d => {
    rtn.concat(d.calls);
  });

  return rtn;
}

const BusiestTimeOfDay = ({ data }) => {

  const [chartData, setChartData] = useState({
    title: "Busiest Time of Day",
    metric: "-",
  });


  useEffect(() => {
    setChartData(null);
    const busy = groupAndFindBusiestHour(data);
    if (busy === undefined) return;

    const time = busy.busiestHour;
    let display = (time !== null) ? `${(time < 12) ? time : (time - 12)}${(time < 12) ? "am" : "pm"}` : "";
    if (display === "0pm") { display = "-" }
    const newData = { title: "Busiest Time of Day", metric: display };

    setChartData(newData);

  }, [data])

  if (chartData === null) return <></>;

  return <DashboardCard item={chartData} />;
}


const TotalCalls = ({ data }) => {

  const [chartData, setChartData] = useState({
    title: "Total Calls",
    metric: "-",
  });


  useEffect(() => {
    const newData = { title: "Total Calls", metric: (Array.isArray(data) === false) ? "0" : data.length };
    setChartData(newData);
  }, [data])

  if (chartData === null) return <></>;

  return <DashboardCard item={chartData} />;
}

const BusiestDay = ({ data }) => {

  const [chartData, setChartData] = useState({
    title: "Busiest Time of Day",
    metric: "-",
  });


  useEffect(() => {
    const busy = groupAndFindBusiestDay(data);
    if (busy === undefined) return;

    const time = busy.busiestDay;
    const newData = { title: "Busiest Day", metric: time };

    setChartData(newData);
  }, [data])

  if (chartData === null) return <></>;

  return <DashboardCard item={chartData} />;
}

export function calculateAverageInitialResponse(data) {
  
  if (!Array.isArray(data) || data.length === 0) {
    return 0; // or you can return null, NaN, or handle it based on your use case
  }
  
  const totalDuration = data.reduce((sum, item) => sum + (item.responseTime || 0), 0);
  const averageDuration = totalDuration / data.length;

  // Round up to the nearest whole number
  const roundedAverage = Math.ceil(averageDuration);

  return roundedAverage;
}

export function calculateAverageDuration(data) {
  
  if (!Array.isArray(data) || data.length === 0) {
    return 0; // or you can return null, NaN, or handle it based on your use case
  }
  
  const totalDuration = data.reduce((sum, item) => sum + (item.durationSeconds || 0), 0);
  const averageDuration = totalDuration / data.length;

  // Round up to the nearest whole number
  const roundedAverage = Math.ceil(averageDuration);

  return roundedAverage;
}

const AverageResponseTime = ({ data }) => {

  const [chartData, setChartData] = useState({
    title: "Average Initial Response Time",
    metric: "-",
  });

  useEffect(() => {
    const dur = calculateAverageInitialResponse(data);
    if (dur === undefined) return;

    const newData = { title: "Average Initial Response Time", metric: secondsToHHMMSS(dur) };

    setChartData(newData);
  }, [data])

  if (chartData === null) return <></>;
  const tooltipContent = <div><b>Initial Response Time: </b>The time taken from when a resident's call is raised to when a care staff member first attends to that call. This focuses on the promptness of the first engagement rather than the total resolution time</div>;
  return <DashboardCard item={chartData} tooltip={tooltipContent} />;
}

const ShowCard = (props) => {

  return (
    <div className={`${(props.noPadding) ? "card_template_no_padding" : "card_template"} card_layout ${props.className}`}>
      {(props.title !== "") && <h4>{props.title}</h4>}
      {props.children}
    </div>
  );
}

const ShowCardRed = (props) => {
  return (
    <div
      className={`card_template_no_padding card_layout ${props.className}`}
    >
      {(props.title !== "") && <h4>{props.title}</h4>}
      {props.children}
    </div>
  );
}

const ProReactPie = ({ data }) => {

  const [pieData, setPieData] = useState(null);
  const [groupData, setGroupData] = useState(null)
  useEffect(() => {
    if (data === null) return;
    const tmp = groupBy(data, "proreact");
    setGroupData(tmp)
    setPieData(
      Object.keys(tmp).map(k => {
        return {
          id: k,
          value: tmp[k].length,
          label: k,
          color: getCallColours(k)
        }
      })
    )

  }, [data])

  return <PieChartCustom data={pieData} rawData={groupData} columns={columns_template_livecalls} />;

}

const CallsByRoom = ({ data, start, end }) => {

  const [chartData, setChartData] = useState(null);
  const [chartKeys, setChartKeys] = useState(null);
  const [roomList, setRoomList] = useState(null);
  const [labelList, setLabelList] = useState(null);
  const [masterData, setMasterData] = useState(null);

  useEffect(() => {

    if (data === null) return; const groups = groupBy(data, "callType");
    const datesArr = Object.keys(groupBy(data, "room"));
    const tmp = groupBy(data, 'room')
    const tmp2 = Object.values(tmp).map(d=>groupBy(d,'callType'))
    const tmp3 = getGroupDatasetby_(tmp2)
    setRoomList(tmp2)
    setLabelList(tmp3)
    
    let dat = {
      labels: datesArr,
      datasets: getGroupDatasetby_(tmp2)
      // datasets: getGroupedDatasetByKey(groups, "room")
    };

    setChartKeys(dat);
    setChartData(dat);

  }, [data])

  const handleClick=(d)=>{
    if(d !== null && d.length !== 0) {
      setMasterData(roomList[d[0].index][labelList[d[0].datasetIndex].label])
    }
  }

  const options = {
    responsive: true,
    plugins: {
      legend: { display: false },
      title: { display: false },
      zoom: {
        pan: {
          enabled: true,
          mode: 'xy', // Enable panning in both x and y directions
        },
        zoom: {
          pinch: {
            enabled: true, // Enable pinch zooming
          },
          wheel: {
            enabled: true, // Enable zooming with the mouse wheel
          },
          mode: 'xy', // Enable zooming in both x and y directions
        },
      },
    },
    options: {
      scales: {
        y: {
          ticks: {
            // Include a dollar sign in the ticks
            callback: function (value, index, ticks) {
              return '$' + value;
            }
          }
        }
      }
    },
    onClick: (event, d) => {handleClick(d)}

  };


  if ((chartData === null) || (chartKeys === null)) return <></>;
  return <Insight_BarChartCustom barData={chartData} barKeys={chartKeys} barOptions={options} selectedData={masterData} setSelectedData={setMasterData} columns={columns_template_livecalls}/>;

}


const CallsByMonth = ({ data, start, end }) => {

  const [chartData, setChartData] = useState(null);
  const [chartKeys, setChartKeys] = useState(null);
  const [monthList, setMonthList] = useState(null);
  const [labelList, setLabelList] = useState(null);
  const [masterData, setMasterData] = useState(null);

  useEffect(() => {

    if (data === null) return; const groups = groupBy(data, "callType");
    const datesArr = Object.keys(groupBy(data, "StartMonth")).map(k => getMonthNameFromNumber(k));//getDaysArray(start, end);
    const tmp = groupBy(data, 'StartMonth')
    const tmp2 = Object.values(tmp).map(d=>groupBy(d,'callType'))
    const tmp3 = getGroupDatasetby_(tmp2)
    let dat = {
      labels: datesArr,
      datasets: getGroupDatasetby_(tmp2)
      // datasets: getGroupedDatasetByKey(groups, "room")
    };

    setChartKeys(dat);
    setChartData(dat);
    setMonthList(tmp2)
    setLabelList(tmp3)

  }, [data])

  const handleClick=(d)=>{
    if(d !== null && d.length !== 0) {
      setMasterData(monthList[d[0].index][labelList[d[0].datasetIndex].label])
    }
  }

  const options = {
    responsive: true,
    plugins: {
      legend: { display: false },
      title: { display: false },
      zoom: {
        pan: {
          enabled: true,
          mode: 'xy', // Enable panning in both x and y directions
        },
        zoom: {
          pinch: {
            enabled: true, // Enable pinch zooming
          },
          wheel: {
            enabled: true, // Enable zooming with the mouse wheel
          },
          mode: 'xy', // Enable zooming in both x and y directions
        },
      },
    },
    scales: {
      x: {
        stacked: true,

        callback: function (value, index, ticks, p) {
          return getMonthNameFromNumber(value);
        }

      },
      y: {
        stacked: true,
      },
    },
    onClick: (event, d) => {handleClick(d)}
  };


  if ((chartData === null) || (chartKeys === null)) return <></>;
  return <Insight_BarChartCustom barData={chartData} barKeys={chartKeys} barOptions={options} selectedData={masterData} setSelectedData={setMasterData} columns={columns_template_livecalls}/>;

}

const CallsByZone = ({ data, start, end }) => {

  const [chartData, setChartData] = useState(null);
  const [chartKeys, setChartKeys] = useState(null);
  const [zoneList, setZoneList] = useState(null);
  const [labelList, setLabelList] = useState(null);
  const [masterData, setMasterData] = useState(null);

  useEffect(() => {

    if (data === null) return; const groups = groupBy(data, "callType");
    const datesArr = Object.keys(groupBy(data, "zone"));
    const tmp = groupBy(data, 'zone')
    const tmp2 = Object.values(tmp).map(d=>groupBy(d,'callType'))
    const tmp3 = getGroupDatasetby_(tmp2)

    let dat = {
      labels: datesArr,
      datasets: getGroupDatasetby_(tmp2)
      // datasets: getGroupedDatasetByKey(groups, "room")
    };

    setChartKeys(dat);
    setChartData(dat);
    setZoneList(tmp2)
    setLabelList(tmp3)

  }, [data])

  const handleClick=(d)=>{
    if(d !== null && d.length !== 0) {
      setMasterData(zoneList[d[0].index][labelList[d[0].datasetIndex].label])
    }
  }

  const options = {
    responsive: true,
    plugins: {
      legend: { display: false },
      title: { display: false },
      zoom: {
        pan: {
          enabled: true,
          mode: 'xy', // Enable panning in both x and y directions
        },
        zoom: {
          pinch: {
            enabled: true, // Enable pinch zooming
          },
          wheel: {
            enabled: true, // Enable zooming with the mouse wheel
          },
          mode: 'xy', // Enable zooming in both x and y directions
        },
      },
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
    onClick: (event, d) => {handleClick(d)}
  };


  if ((chartData === null) || (chartKeys === null)) return <></>;
  return <Insight_BarChartCustom barData={chartData} barKeys={chartKeys} barOptions={options} selectedData={masterData} setSelectedData={setMasterData} columns={columns_template_livecalls}/>;

}


const CallsByZones = ({ data }) => {

  const [chartData, setChartData] = useState(null);
  const [chartKeys, setChartKeys] = useState(null);

  useEffect(() => {

    if (data === null) return;
    const tmp = groupBy(data, "zone");
    const values = Object.values(tmp).map(v => v.length);
    const keys = Object.keys(tmp).map(v => v);

    setChartKeys(keys);
    setChartData(values);


  }, [data])

  if ((chartData === null) || (chartKeys === null)) return <></>;
  return <VerticalHistogram data={chartData} keys={chartKeys} />;

}


function getMonthNameFromNumber(monthNumber) {
  const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  // Check if the provided month number is valid
  if (monthNumber >= 1 && monthNumber <= 12) {
    return monthNames[monthNumber - 1]; // Adjust index (1-based to 0-based)
  } else {
    return 'Invalid Month Number';
  }
}

/*
  
  const CallsByDay=({data})=>{

  const [chartData, setChartData] = useState(null);
  const [chartKeys, setChartKeys] = useState(null);

  useEffect(()=>{
    
    if (data===null) return;
    const tmp = groupBy(data, "StartDate");
    const values = Object.values(tmp).map(v=>v.length);
    const keys = Object.keys(tmp);

    setChartKeys(keys);
    setChartData(values);
    
  }, [data])
  
  if ((chartData===null)||(chartKeys===null)) return <></>;
  return <VerticalHistogram data={chartData} keys={chartKeys} />;

}

*/

function getGroupedDataset(groupedCalls, datesArr) {
  const rtnObject = Object.keys(groupedCalls).map((k => {
    return {
      label: k,
      data: sumDurationByDate(groupedCalls[k], datesArr),
      backgroundColor: getColour(k),
      hoverBackgroundColor: 'rgba(75, 192, 192, 0.2)'
    };
  }))
  return rtnObject;
}

function getGroupDatasetby_(roomData){
  // Initialize an empty object to store the result
  const rtnObject = [];

  // Collect all possible keys (labels) from the input array
  const allKeys = [...new Set(roomData.flatMap(Object.keys))];

  // Initialize result array for all possible keys using numeric indices
  allKeys.forEach((key, index) => {
    rtnObject[index] = {
      label: key,
      data: [],
      backgroundColor: getColour(key), // Assuming getColour is a function that returns a color
      hoverBackgroundColor: 'rgba(75, 192, 192, 0.2)'
    };
  });

  // Iterate over the input array and process each object
  roomData.forEach(item => {
    allKeys.forEach((key, index) => {
      if (item[key]) {
        rtnObject[index].data.push(item[key].length);
      } else {
        rtnObject[index].data.push(0);
      }
    });
  });

  return rtnObject
}

function getGroupedDatasetByKey(groupedCalls, key) {

  const rtnObject = Object.keys(groupedCalls).map((k => {

    return {
      label: k,
      data: Object.values(groupBy(groupedCalls[k], key)).map(d => d.length),
      backgroundColor: getColour(k)
    };
  }))
  return rtnObject;
}

function sumDurationByDate(objects, dates) {
  // Create an object to store the sum of durationSeconds for each date
  const dateDurations = {};

  // Initialize dateDurations with zero for each date in the dates array
  dates.forEach(date => {
    dateDurations[date] = 0;
  });

  // Sum the durationSeconds for each date
  objects.forEach(obj => {
    const objDate = obj.StartDate;
    if (dateDurations.hasOwnProperty(objDate)) {
      dateDurations[objDate] += 1;
    }
  });

  // Create the data array from dateDurations
  const data = dates.map(date => dateDurations[date]);

  // Return the final object
  return data;
}

function fillDateGap(data, start, end){
  // Function to format date in YYYY-MM-DD
  const startDate = new Date(start);
  const endDate = new Date(end);

  function formatDate(date) {
    const year = date.getUTCFullYear();
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
    const day = date.getUTCDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  // Iterate over each day in the range
  for (let date = startDate; date <= endDate; date.setUTCDate(date.getUTCDate() + 1)) {
    const formattedDate = formatDate(date);

    // If the date doesn't exist in check1, add it with an empty array
    if (!data.hasOwnProperty(formattedDate)) {
      data[formattedDate] = [];
    }
  }

  // Sort the dates in ascending order
  const sortedData = Object.keys(data)
    .sort((a, b) => new Date(a) - new Date(b))
    .reduce((acc, key) => {
      acc[key] = data[key];
      return acc;
    }, {});

  return sortedData
}

function fillGroupGap(data, type){
  // Function to group by callType
  function groupBy(array, key) {
    const result = {};
  
    array.forEach(item => {
      const groupKey = item[key];
      if (!result[groupKey]) {
        result[groupKey] = []; // Initialize as an array if it doesn't exist
      }
      result[groupKey].push(item);
    });
  
    return result;
  }

  // Initialize an empty array to store the result
  const sortedData = [];

    // Iterate over check1 using Object.entries to maintain key-value pairs
  Object.entries(data).forEach(([date, d]) => {
    if (d.length === 0) {
      sortedData.push({});  // Add empty array if no data
    } else {
      // Otherwise, push the data directly (or processed as needed)
      sortedData.push(groupBy(d, type));  // Here you can add more logic if needed
    }
  });

  return sortedData
}

const CallsByDay = ({ data, start, end }) => {

  const [chartData, setChartData] = useState(null);
  const [chartKeys, setChartKeys] = useState(null);
  const [data_DateArr, setData_DateArr] = useState(null);
  const [masterData, setMasterData] = useState(null);

  useEffect(() => {

    if (data === null) return;     let  tmp = fillDateGap(groupBy(data, "StartDate"), start, end)
    let tmpA = fillGroupGap(tmp, 'callType')

    const values = Object.values(tmp).map(v => v.length);
    const keys = Object.keys(tmp);

    const groups = groupBy(data, "callType");
    const datesArr = getDaysArray(start, end);

    let dat = {
      labels: datesArr,
      datasets: getGroupedDataset(groups, datesArr),
    };

    setChartKeys(dat);
    setChartData(dat);
    setData_DateArr(tmpA)

  }, [data])

  const handleClick = (d) => {
    if(d !== null && d.length !== 0) {
      setMasterData(data_DateArr[d[0].index + 1][chartData.datasets[d[0].datasetIndex].label])
    }
  }

  const options = {
    responsive: true,
    plugins: {
      legend: { display: false },
      title: { display: false },
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
    onClick: (event, d) => {handleClick(d)}
  };


  if ((chartData === null) || (chartKeys === null)) return <></>;
  return <Insight_BarChartCustom className='insight_barChart' barData={chartData} barKeys={chartKeys} barOptions={options} selectedData={masterData} setSelectedData={setMasterData} columns={columns_template_livecalls}/>;

}


const AllCallsPie = ({ data }) => {

  const [pieData, setPieData] = useState(null);
  const [groupData, setGroupData] = useState(null)

  useEffect(() => {
    if (data === null) return;
    const tmp = groupBy(data, "callType");
    setGroupData(tmp)
    setPieData(
      Object.keys(tmp).map(k => {
        return {
          id: k,
          value: tmp[k].length,
          label: k,
          color: getCallColours(k)
        }
      })
    )

  }, [data])

  return <PieChartCustom data={pieData} rawData={groupData} columns={columns_template_all_livecalls} />;

}

const OriCallsPie = ({ data }) => {

  const [pieData, setPieData] = useState(null);
  const [groupData, setGroupData] = useState(null)

  useEffect(() => {
    if (data === null) return;
    const tmp = groupBy(data, "callType");
    setGroupData(tmp)
    setPieData(
      Object.keys(tmp).map(k => {
        return {
          id: k,
          value: tmp[k].length,
          label: k,
          color: getCallColours(k)
        }
      })
    )

  }, [data])

  return <PieChartCustom data={pieData} rawData={groupData} columns={columns_template_livecalls} />;

}


const EmergencyNon = ({ data }) => {

  const [pieData, setPieData] = useState(null);
  const [groupData, setGroupData] = useState(null);

  useEffect(() => {
    if (data === null) return;
    const tmp = groupBy(data, "EmergencyNon");
    setGroupData(tmp)
    setPieData(
      Object.keys(tmp).map(k => {
        return {
          id: k,
          value: tmp[k].length,
          label: k,
          color: getCallColours(k)
        }
      })
    )

  }, [data])

  return <PieChartCustom data={pieData} rawData={groupData} columns={columns_template_livecalls} />;

}

function getCallColours(callType) {

  try { callType = callType.toLowerCase(); } catch (e) {
    //return "#48C9B0";
    return "#4cc1bd";
  }
  if (callType === "emergency") return "red";
  if (callType === "call") return "rgb(245, 129, 78)";
  if (callType === "sense") return "#914397";
  if (callType === "attendance") return "rgb(148, 202, 102)";
  if (callType === "accessory") return "#914397";
  if (callType === "assistance") return "#F8DA3C";
  if (callType === "carecall") return "rgb(225, 21, 131)";
  if (callType === "call") return "orange";
  if (callType === "visit") return "rgb(1, 87, 62)";
  if (callType === "proactive") return "#5DADE2";
  if (callType === "reactive") return "#F5B041";
  //return "#48C9B0";
  return "#4cc1bd";
}


function displayDateFromISO(dt) {
  dt = dt.toUTCString();
  //dt = dt.split(", ")[1];
  dt = dt.split(" ");
  return `${dt[1]} ${dt[2]} ${dt[3]}`;
}

function groupAndFindBusiestDay(data) {
  if (!data || data.length === 0) return;

  // Create an object to store groups based on the day
  const groupedData = {};

  // Iterate through the array
  data.forEach(entry => {
    // Extract the day from the 'start' field
    const startDate = new Date(entry.start);
    const startDay = displayDateFromISO(startDate);//startDate.toISOString().split('T')[0]; // Extracting the day portion

    // If the day group doesn't exist, create it
    if (!groupedData[startDay]) {
      groupedData[startDay] = [];
    }

    // Push the entry to the corresponding day group
    groupedData[startDay].push(entry);
  });

  // Find the busiest day
  let busiestDay = null;
  let maxEntries = 0;

  for (const day in groupedData) {
    if (groupedData[day].length > maxEntries) {
      maxEntries = groupedData[day].length;
      busiestDay = day;
    }
  }

  return {
    groupedData,
    busiestDay,
    busiestDayEntries: groupedData[busiestDay],
  };
}

function groupAndFindBusiestHour(data) {
  if (data === null) return;
  if (data === undefined) return;
  // Create an object to store groups based on the hour
  const groupedData = {};

  // Iterate through the array
  data.forEach(entry => {
    // Extract the hour from the 'start' field
    const startHour = new Date(entry.start).getHours();

    // If the hour group doesn't exist, create it
    if (!groupedData[startHour]) {
      groupedData[startHour] = [];
    }

    // Push the entry to the corresponding hour group
    groupedData[startHour].push(entry);
  });

  // Find the busiest hour
  let busiestHour = null;
  let maxEntries = 0;

  for (const hour in groupedData) {
    if (groupedData[hour].length > maxEntries) {
      maxEntries = groupedData[hour].length;
      busiestHour = hour;
    }
  }

  return {
    groupedData,
    busiestHour: busiestHour,
    busiestHourEntries: groupedData[busiestHour],
  };
}


